<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0"><tbody><tr>
                  <td align="left"><div align="center"><font color="#000000">					  </font>
                    <div align="center">                      <font size="6" color="#000099"><b><font face="Verdana, Arial, Helvetica, sans-serif">
                    </font></b></font>
                      <center>
                        <font size="+2">Available Image Sets </font>                        
                      </center> 
                      <font color="#000033"><br>
                      </font>
                      <div align="center"><font color="#000033"><br>
                        </font>
                          <table width="84%" border="0" bgcolor="#FFFFCC" align="center">
                            <tbody><tr>
                              <td height="78"><div align="center"><font color="#000033"><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><b><br>
                                  </b></font><font face="Verdana, Arial, Helvetica, sans-serif"><span class="style3">Following is a listing of image sets from the database.&nbsp;&nbsp;You can search the image sets using the [&nbsp;<router-link to="/repository/WILD/searchengine">search engine</router-link>&nbsp;] provided. </span></font></font><span class="style3"><br>
                                  <font color="#000000" face="Verdana, Arial, Helvetica, sans-serif">To obtain more WILD data, click [&nbsp;<router-link to="/repository/WILD/request">here</router-link>&nbsp;] to contact the WILD adminstrators</font> </span><b><font color="#CCCCCC"></font><font color="#CCCCCC" size="3"><br>
                                                  </font></b></div></td>
                            </tr>
                          </tbody></table>
                          <br>
                          <br>
                          <strong><font size="3" face="Verdana, Arial, Helvetica, sans-serif">Images taken in Clear Weather</font></strong><br>
    <br>
    <table width="47%" height="278" border="1">
      <tbody><tr>
        <td width="49%" height="84"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><strong>Clear sky</strong></font></div></td>
        <td width="51%"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><strong>January 2002 -- June</strong> <strong>2002</strong><br>
                    <strong>171 images</strong></font></div></td>
      </tr>
      <tr>
        <td width="49%" height="92"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><strong>Overcast sky </strong></font></div></td>
        <td width="51%"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><strong><br>
          January 2002-- June</strong> <strong>2002</strong><br>
          <strong>166 images</strong></font></div></td>
      </tr>
      <tr>
        <td width="49%" height="92"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><strong>Partly cloudy sky</strong></font></div></td>
        <td width="51%"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><br>
                    <strong>January 2002-- June</strong> <strong>2002</strong><br>
                    <strong>145 images </strong></font></div></td>
      </tr>
    </tbody></table>
    <br>
    <br>
    <table width="47%" border="1">
      <tbody><tr>
        <td height="81"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><strong>All images taken at 6am, 9am, 12n, 3pm, 6pm and 9pm over <br>
          one month.<br>
          <br>
          June 2002 --120 images</strong></font></div></td>
      </tr>
    </tbody></table>
    <br>
    <br>
    <hr width="700" size="0">
    <br>
    <br>
    <br>
    <strong><font size="3" face="Verdana, Arial, Helvetica, sans-serif">Images taken in Bad Weather</font></strong><br>
    <br>
    <table width="47%" height="153" border="1">
      <tbody><tr>
        <td width="50%"><div align="center" class="style3"><strong><font face="Verdana, Arial, Helvetica, sans-serif">Mist</font></strong></div></td>
        <td width="50%"><div align="center" class="style3"><strong><font face="Verdana, Arial, Helvetica, sans-serif">170 images</font></strong></div></td>
      </tr>
      <tr>
        <td width="50%"><div align="center" class="style3"><strong><font face="Verdana, Arial, Helvetica, sans-serif">Rain</font></strong></div></td>
        <td width="50%"><div align="center" class="style3"><strong><font face="Verdana, Arial, Helvetica, sans-serif">110 images</font></strong></div></td>
      </tr>
      <tr>
        <td width="50%"><div align="center" class="style3"><strong><font face="Verdana, Arial, Helvetica, sans-serif">Haze</font></strong></div></td>
        <td width="50%"><div align="center" class="style3"><strong><font face="Verdana, Arial, Helvetica, sans-serif">63 images </font></strong></div></td>
      </tr>
      <tr>
        <td width="50%"><div align="center" class="style3"><strong><font face="Verdana, Arial, Helvetica, sans-serif">Fog</font></strong></div></td>
        <td width="50%"><div align="center" class="style3"><strong><font face="Verdana, Arial, Helvetica, sans-serif">6 images</font></strong></div></td>
      </tr>
    </tbody></table>
                      </div>
                      <br>
                      <br>
                      <hr width="700" size="0">
                      <br>
</div>
                    <table width="100%" border="0">
  <tbody><tr>
    <td width="49%"><div align="center"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">[ <router-link to="/repository/WILD">Back to WILD Home</router-link> ]</font></div></td>
  </tr>
</tbody></table>
<br>
<br>
<table width="100%" border="0" align="center">
  <tbody><tr>
    <td width="49%"><div align="center"><font color="#000033" size="2" face="Verdana, Arial, Helvetica, sans-serif">Contact: <a href="mailto:wild@cs.columbia.edu">wild@cs.columbia.edu</a></font></div></td>
  </tr>
</tbody></table>
<br>
                  </div></td>
                </tr>
        </tbody></table>

  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>